
import Vue from "vue";
import { InformationData, Mentorcast } from "@/types/interfaces";
import moment from "moment";
import ShareDropdown from "@/components/ShareDropdown.vue";

export default Vue.extend({
  name: "MentorcastPost",
  components: {
    ShareDropdown
  },
  props: {
    post: {
      type: Object as () => Mentorcast
    },
    text: String,
    date: String,
    index: {
      type: Number
    },
    isAvailable: Boolean,
    feedback: Boolean
  },
  data() {
    return {
      // Mentorcast information table data
      infoData: [
        {
          name: "start_date",
          label: (this as any).$t("mentorcastHero.postShareBtn"),
          icon: "calendar",
          value: null
        },
        {
          name: "start_time",
          label: (this as any).$t("mentorcastHero.postInfoDataTime"),
          icon: "time",
          value: null
        },
        {
          name: "seat_price",
          label: (this as any).$t("mentorcastHero.postInfoSeatPrice"),
          icon: "outline-paid",
          value: null
        },
        {
          name: "max_seats",
          label: (this as any).$t("mentorcastHero.postInfoDataNumberOfSeats"),
          icon: "seat-block",
          value: null
        }
      ] as InformationData[]
    };
  },
  mounted(): void {
    // Find mentorcast information by name
    for (const key of Object.keys(this.post)) {
      const index = this.infoData.findIndex(i => i.name === key);
      if (index > -1) {
        if (key === "start_time") {
          this.infoData[index].value = moment(
            this.post[key],
            "hh:mm:ss"
          ).format("hh:mm A");
          continue;
        } else if (key === "seat_price") {
          this.infoData[index].value = `$${this.post[key as keyof Mentorcast]}`;
        } else this.infoData[index].value = this.post[key as keyof Mentorcast];
      }
    }
  },
  methods: {
    getPostUrl(post: any) {
      return "https://mentorice.net/mentorcasts/" + post.uuid;
    },
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    },
    share() {
      console.log("Share button was clicked!");
    }
  }
});
